// Plugin: Ngx-datatable
// github: https://github.com/swimlane/ngx-datatable


.ngx-datatable {
  .row-qs-icons {
    color: #01B286;
    font-size: 100%;
  }

  &.bootstrap {
    .datatable-header {
      .datatable-header-cell {
        border-bottom-color: $border-color;
      }
    }
    .datatable-body {
      .datatable-body-row {
        border-top-color: $border-color;
        &.datatable-row-even {
          background-color: $table-striped-bg;
        }
        .datatable-body-cell {
          text-align: left; // RTL fix
        }
      }
    }
    .datatable-footer {
      background: $table-striped-bg;
      color: $body-color;
      .datatable-pager {
        text-align: right; // RTL fix
        ul {
          li {
            &:not(.disabled) {
              a {
                color: $body-color;
              }
              &.active,
              &:hover {
                a {
                  background-color: $primary;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
