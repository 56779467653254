@font-face {
  font-family: 'pulse';
  src: url('../font/pulse.eot?60127011');
  src: url('../font/pulse.eot?60127011#iefix') format('embedded-opentype'),
       url('../font/pulse.woff2?60127011') format('woff2'),
       url('../font/pulse.woff?60127011') format('woff'),
       url('../font/pulse.ttf?60127011') format('truetype'),
       url('../font/pulse.svg?60127011#pulse') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pulse';
    src: url('../font/pulse.svg?60127011#pulse') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "pulse";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-attach:before { content: '\e800'; } /* '' */
.icon-comment:before { content: '\e801'; } /* '' */
.icon-ai-chatting:before { content: '\e802'; } /* '' */
.icon-document-writer:before { content: '\e803'; } /* '' */
.icon-comments:before { content: '\e804'; } /* '' */
.icon-collapse:before { content: '\e805'; } /* '' */
.icon-collapse-arrow:before { content: '\e806'; } /* '' */
.icon-edit-text-file:before { content: '\e807'; } /* '' */
.icon-binder:before { content: '\e808'; } /* '' */
.icon-expand:before { content: '\e809'; } /* '' */
.icon-search-bar:before { content: '\e80a'; } /* '' */
.icon-pencil:before { content: '\e80b'; } /* '' */
.icon-print:before { content: '\e80c'; } /* '' */
.icon-new-copy:before { content: '\e80d'; } /* '' */
.icon-alarm:before { content: '\e80e'; } /* '' */
.icon-search:before { content: '\e80f'; } /* '' */
.icon-writer-male:before { content: '\e810'; } /* '' */
.icon-expand-arrow:before { content: '\e811'; } /* '' */
.icon-filter-mail:before { content: '\e812'; } /* '' */
.icon-star:before { content: '\e813'; } /* '' */
.icon-settings:before { content: '\e814'; } /* '' */
.icon-note:before { content: '\e815'; } /* '' */
.icon-what-i-do:before { content: '\e816'; } /* '' */
.icon-notification:before { content: '\e817'; } /* '' */
.icon-copy-link-new:before { content: '\e818'; } /* '' */
.icon-chat-message:before { content: '\e81c'; } /* '' */
.icon-form:before { content: '\e825'; } /* '' */
.icon-inquiry:before { content: '\e826'; } /* '' */
.icon-notification-filled:before { content: '\e829'; } /* '' */
.icon-star-filled:before { content: '\e830'; } /* '' */
.icon-info:before { content: '\e834'; } /* '' */
.icon-document:before { content: '\e835'; } /* '' */
.icon-user:before { content: '\e836'; } /* '' */
.icon-folder:before { content: '\e837'; } /* '' */
.icon-opened-folder:before { content: '\e838'; } /* '' */
.icon-copy-link:before { content: '\e83a'; } /* '' */
.icon-treeview:before { content: '\e83c'; } /* '' */
.icon-listview:before { content: '\e83d'; } /* '' */
.icon-light-bulb:before { content: '\e83f'; } /* '' */
.icon-dot:before { content: '\e840'; } /* '' */
