// Write your custom css/scss here

body, html {
  overscroll-behavior-x: contain;
}

// buttons colors
.btn-outline-success {
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;
}
:root {
  /*** Brand Colors (default) ***/
  --brand-color: red;
  --publisher-level-0: #016007;
  --publisher-level-1: #008a08;
  --publisher-level-2: #00b707;
}

.document-page-width {
  max-width: 250mm;
}


.page-content {
  @extend .pt-2, .pt-md-3;
  @extend .px-0, .px-md-3;

  @media(max-width: 767px) {
    padding-bottom: 0;
  }

  &.no-padding {
    @extend .p-0;
  }
}


.row-responsive-margin {
  @extend .mx-1, .mx-md-0;
}

.row-full-width-mobile {
  @extend .mx-md-0;
}

.mobile-remove-after::after {
  @media(max-width: 767px) {
    display: none !important;
  }
}

.bg-brand {
  background-color: var(--brand-color) !important;
}

.text-brand {
  color: var(--brand-color) !important;
}

.badge-publisher-level-0 {
  background-color: var(--publisher-level-0);
}

.badge-publisher-level-1 {
  background-color: var(--publisher-level-1);
}

.badge-publisher-level-2 {
  background-color: var(--publisher-level-2);
}

.preview-document-tooltip {
  position: absolute;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #535353;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  display: none;
  z-index: 1000;
}


.btn-success {
  --bs-btn-color: white;
}

.btn-active {

}

.btn {
  --bs-btn-border-radius: 1rem
}

.ngx-datatable {
  &.bootstrap .datatable-footer {
    background: none;
  }
  &.bootstrap .datatable-header .datatable-header-cell {
    padding-top: 0;
  }

  .search-result-title-cell {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    .datatable-body-cell-label > a {
      #padding: 0.75rem;
      #display: block;
    }
  }

  .page-count {
    display: none;
  }
}

.navbar {
  // remove this for sticky header
  position: absolute;
}


.sidebar .sidebar-header {
  padding-left: 20px;
}


.search-tooltip {
  opacity: 100%;
  z-index: 1000;
  --bs-tooltip-bg: white;
  --bs-tooltip-color: black;

  &.show {
    opacity: 100%;
  }

  .tooltip-inner {
    text-align: left;
    font-size: 0.9rem;
    max-width: 40vw;
    border: 1px solid #919191;
    .med {
      font-style: italic;
    }
    .max {
      font-weight: bold;
    }
  }

  .tooltip-arrow:before {
    border-bottom-color: #919191 !important;
  }


}

/** tree view */
.node-content-wrapper {
  padding: 0;
}
